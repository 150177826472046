<template>
  <div class="flex h-full">
    <loader :loading="loading" :backdrop="true"/>
    <div class="side-panel">
      <DetailsSidePanel>
        <template v-slot:heading>shared authorized data block for {{type}}</template>
        <template v-slot:text>shared authorized data block can include records with preregistered shared data keys only.
          Removing the existing data records is considered to be breaking changes and not allowed in the edit mode.
          Create a new version of the data block instead.
        </template>
      </DetailsSidePanel>
    </div>
    <div class="w-2/3">
      <data-block-form
        :initialValues="record"
        :on-submit="handleSubmit"
        @close="redirectToList"
        edit-mode
      />
    </div>
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import Loader from '@/components/ui/Loader';
  import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';
  import DataBlockForm from "./DataBlockForm";

  export default {
  name: 'DataBlockEdit',
  mixins: [ModalNavigation, NotifyMixin, ActionsMixin],
  components: {
    Loader,
    DataBlockForm,
    DetailsSidePanel,
  },
  data() {
    return {
      record: {},
      loading: false,
    };
  },
  computed: {
    basePath() {
      return this.$route.name.replace('dataBlock.edit', 'details');
    },
    type() {
      return this.$route.params.type;
    },
    name() {
      return this.$route.params.name;
    },
    requestParams() {
      return {
        name: this.name,
        type: this.type,
        version: this.$route.params.version,
      }
    },
  },
  methods: {
    fetchSharedDataBlock() {
      this.loading = true;
      this.$raaDataProvider.get('sharedDataBlockVersioned', { ...this.requestParams })
        .then(data => {
          this.record = data;
        })
        .catch(error => {
          this.redirectToList();
          this.notifyError(error.message);
        })
        .finally(() => this.loading = false);
    },
    redirectToList() {
      this.$router.replace({
        name: this.basePath,
        params: {direction: 'back', type: this.type}
      });
    },
    handleSubmit({ description, records }) {
      if (!this.loading) {
        this.loading = true;
        return this.$raaDataProvider.update('sharedDataBlockVersioned', {
          data: {
            description,
            records,
          },
          ...this.requestParams
        })
          .then(() => {
            this.notifySuccess(`Data block ${this.name} has been updated`);

            this.redirectToList();
          })
          .catch(error => this.notifyError(error.message))
          .finally(() => this.loading = false);
      }
    },
  },
  mounted() {
    this.fetchSharedDataBlock();
    this.setActiveModalTitle(`edit data block ${this.name}`);
    this.setActiveModalBreadcrumbName(`edit data block`);
  },
};
</script>
